import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";

class ParentForm extends Component {
  state = {
    selectedIndex: 1,
  };

  handleSelect = (sel) => (e) => {
    if (e) e.preventDefault();
    if (sel === "ayah") {
      this.setState({
        selectedIndex: 1,
      });
    } else if (sel === "ibu") {
      this.setState({
        selectedIndex: 2,
      });
    } else {
      this.setState({
        selectedIndex: 3,
      });
    }
  };
  render() {
    const { selectedIndex } = this.state;
    const { father, mother, representative } = this.props;
    return (
      <View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View>
            <button
              className="btn btn-sm btn-rounded shadow"
              style={{
                borderRadius: "30px",
                backgroundColor: selectedIndex === 1 ? "#00a8ff" : "#fff",
                color: selectedIndex === 1 ? "#fff" : "#bdbdbd",
                marginRight: 10,
                paddingLeft: 20,
                paddingRight: 20,
              }}
              onClick={this.handleSelect("ayah")}
            >
              Ayah
            </button>
          </View>
          <View>
            <button
              className="btn btn-sm shadow btn-rounded"
              style={{
                borderRadius: "30px",
                backgroundColor: selectedIndex === 2 ? "#00a8ff" : "#fff",
                color: selectedIndex === 2 ? "#fff" : "#bdbdbd",
                marginRight: 10,
                paddingLeft: 20,
                paddingRight: 20,
              }}
              onClick={this.handleSelect("ibu")}
            >
              Ibu
            </button>
          </View>
          <View>
            <button
              className="btn btn-sm shadow btn-rounded"
              style={{
                borderRadius: "30px",
                backgroundColor: selectedIndex === 3 ? "#00a8ff" : "#fff",
                color: selectedIndex === 3 ? "#fff" : "#bdbdbd",
                marginRight: 10,
                paddingLeft: 20,
                paddingRight: 20,
              }}
              onClick={this.handleSelect("wali")}
            >
              Wali
            </button>
          </View>
        </View>

        <View
          style={{
            marginTop: 10,
          }}
        >
          {selectedIndex === 1 ? (
            <section
              className="card"
              style={{
                padding: 10,
                display: selectedIndex === 1 ? "block" : "none",
              }}
            >
              <label
                style={{
                  fontSize: 18,
                }}
              >
                <b>Data Ayah</b>
              </label>
              <hr
                style={{
                  margin: "0px 0px 10px 0px",
                }}
              />

              <div className="form-group">
                <label>{"Nama Lengkap Ayah"}</label>
                <input
                  className="form-control"
                  value={father && father.name ? father.name : ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Email"}</label>
                <input
                  className="form-control"
                  value={father && father.email ? father.email : ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"NIK"}</label>
                <input
                  className="form-control"
                  value={father && father.nin ? father.nin : ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Status Orang Tua"}</label>
                <select
                  className="form-control"
                  value={father && father.status ? father.status : ""}
                  disabled
                >
                  <option value="" disabled>
                    {"Pilih Status Orang Tua"}
                  </option>
                  <option value="Kandung">{"Kandung"}</option>
                  <option value="Tiri">{"Tiri"}</option>
                  <option value="Angkat">{"Angkat"}</option>
                </select>
              </div>
              <div className="form-group">
                <label>{"Keterangan Orang Tua"}</label>
                <select
                  className="form-control"
                  value={father && father.lifeStatus ? father.lifeStatus : ""}
                  disabled
                >
                  <option value="" disabled>
                    {"Pilih Keterangan Orang Tua"}
                  </option>
                  <option value="Hidup">{"Hidup"}</option>
                  <option value="Meninggal">{"Meninggal"}</option>
                </select>
              </div>
              <div className="form-group">
                <label>{"Kota Lahir"}</label>
                <input
                  className="form-control"
                  value={father && father.birthCity ? father.birthCity : ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Tanggal Lahir"}</label>
                <input
                  className="form-control"
                  type="date"
                  value={father && father.birthDate ? father.birthDate : ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Alamat"}</label>
                <input
                  className="form-control"
                  value={father && father.address ? father.address : ""}
                  disabled
                />
              </div>

              <div className="row">
                {"RT" ? (
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>{"RT"}</label>
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        disabled
                        value={father && father.rt ? father.rt : 0}
                      />
                    </div>
                  </div>
                ) : null}
                {"RW" ? (
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>{"RW"}</label>
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        disabled
                        value={father && father.rw ? father.rw : 0}
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              {"Kota" ? (
                <div className="form-group">
                  <label>{"Kota"}</label>
                  <input
                    className="form-control"
                    value={father && father.city ? father.city : ""}
                    disabled
                  />
                </div>
              ) : null}
              <div className="form-group">
                <label>{"Berkebutuhan Khusus"}</label>
                <input
                  className="form-control"
                  value={father && father.disability ? father.disability : ""}
                  disabled
                />
              </div>

              <div className="form-group">
                <label>{"Pendidikan Akhir"}</label>
                <select
                  className="form-control"
                  style={{ maxWidth: 250 }}
                  value={father && father.education ? father.education : ""}
                  disabled
                >
                  <option value="" disabled>
                    Pilih Jenjang Pendidikan
                  </option>
                  <option value="SD">{"SD"}</option>
                  <option value="SMP">{"SMP"}</option>
                  <option value="SMA">{"SMA/Sederajat"}</option>
                  <option value="D3">{"D3"}</option>
                  <option value="S1">{"D4/S1"}</option>
                  <option value="S2">{"S2"}</option>
                  <option value="S3">{"S3"}</option>
                </select>
              </div>
              <div className="form-group">
                <label>{"Pekerjaan"}</label>
                <input
                  className="form-control"
                  value={father && father.job ? father.job : ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Nama Instansi Tempat Bekerja"}</label>
                <input
                  className="form-control"
                  value={father && father.companyName ? father.companyName : ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Penghasilan Ayah"}</label>
                <input
                  className="form-control"
                  value={father && father.salary ? father.salary : ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"No Hp"}</label>
                <input
                  className="form-control"
                  value={
                    father && father.fatherCellphoneNumber
                      ? father.fatherCellphoneNumber
                      : ""
                  }
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"No Whatsapp"}</label>
                <input
                  className="form-control"
                  value={father && father.noWA ? father.noWA : ""}
                  disabled
                />
              </div>
            </section>
          ) : selectedIndex === 2 ? (
            <section
              className="card"
              style={{
                padding: 10,
                display: selectedIndex === 2 ? "block" : "none",
              }}
            >
              <label
                style={{
                  fontSize: 18,
                }}
              >
                <b>Data Ibu</b>
              </label>
              <hr
                style={{
                  margin: "0px 0px 10px 0px",
                }}
              />

              <div className="form-group">
                <label>{"Nama Lengkap Ibu"}</label>
                <input
                  className="form-control"
                  value={mother && mother.name ? mother.name : ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Email"}</label>
                <input
                  className="form-control"
                  value={mother && mother.email ? mother.email : ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"NIK"}</label>
                <input
                  className="form-control"
                  value={mother && mother.nin ? mother.nin : ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Status Orang Tua"}</label>
                <select
                  className="form-control"
                  value={mother && mother.status ? mother.status : ""}
                  disabled
                >
                  <option value="" disabled>
                    {"Pilih Status Orang Tua"}
                  </option>
                  <option value="Kandung">{"Kandung"}</option>
                  <option value="Tiri">{"Tiri"}</option>
                  <option value="Angkat">{"Angkat"}</option>
                </select>
              </div>
              <div className="form-group">
                <label>{"Keterangan Orang Tua"}</label>
                <select
                  className="form-control"
                  value={mother && mother.lifeStatus ? mother.lifeStatus : ""}
                  disabled
                >
                  <option value="" disabled>
                    {"Pilih Keterangan Orang Tua"}
                  </option>
                  <option value="Hidup">{"Hidup"}</option>
                  <option value="Meninggal">{"Meninggal"}</option>
                </select>
              </div>
              <div className="form-group">
                <label>{"Kota Lahir"}</label>
                <input
                  className="form-control"
                  value={mother && mother.birthCity ? mother.birthCity : ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Tanggal Lahir"}</label>
                <input
                  className="form-control"
                  type="date"
                  value={mother && mother.birthDate ? mother.birthDate : ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Alamat"}</label>
                <input
                  className="form-control"
                  value={mother && mother.address ? mother.address : ""}
                  disabled
                />
              </div>

              <div className="row">
                {"RT" ? (
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>{"RT"}</label>
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        disabled
                        value={mother && mother.rt ? mother.rt : 0}
                      />
                    </div>
                  </div>
                ) : null}
                {"RW" ? (
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>{"RW"}</label>
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        disabled
                        value={mother && mother.rw ? mother.rw : 0}
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              {"Kota" ? (
                <div className="form-group">
                  <label>{"Kota"}</label>
                  <input
                    className="form-control"
                    value={mother && mother.city ? mother.city : ""}
                    disabled
                  />
                </div>
              ) : null}
              <div className="form-group">
                <label>{"Berkebutuhan Khusus"}</label>
                <input
                  className="form-control"
                  value={mother && mother.disability ? mother.disability : ""}
                  disabled
                />
              </div>

              <div className="form-group">
                <label>{"Pendidikan Akhir"}</label>
                <select
                  className="form-control"
                  style={{ maxWidth: 250 }}
                  value={mother && mother.education ? mother.education : ""}
                  disabled
                >
                  <option value="" disabled>
                    Pilih Jenjang Pendidikan
                  </option>
                  <option value="SD">{"SD"}</option>
                  <option value="SMP">{"SMP"}</option>
                  <option value="SMA">{"SMA/Sederajat"}</option>
                  <option value="D3">{"D3"}</option>
                  <option value="S1">{"D4/S1"}</option>
                  <option value="S2">{"S2"}</option>
                  <option value="S3">{"S3"}</option>
                </select>
              </div>
              <div className="form-group">
                <label>{"Pekerjaan"}</label>
                <input
                  className="form-control"
                  value={mother && mother.job ? mother.job : ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Nama Instansi Tempat Bekerja"}</label>
                <input
                  className="form-control"
                  value={mother && mother.companyName ? mother.companyName : ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Penghasilan Ibu"}</label>
                <input
                  className="form-control"
                  value={mother && mother.salary ? mother.salary : ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"No Hp"}</label>
                <input
                  className="form-control"
                  value={
                    mother && mother.motherCellphoneNumber
                      ? mother.motherCellphoneNumber
                      : ""
                  }
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"No Whatsapp"}</label>
                <input
                  className="form-control"
                  value={mother && mother.noWA ? mother.noWA : ""}
                  disabled
                />
              </div>
            </section>
          ) : selectedIndex === 3 ? (
            <section
              className="card"
              style={{
                padding: 10,
                display: selectedIndex === 3 ? "block" : "none",
              }}
            >
              <label
                style={{
                  fontSize: 18,
                }}
              >
                <b>Data Wali</b>
              </label>
              <hr
                style={{
                  margin: "0px 0px 10px 0px",
                }}
              />

              <div className="form-group">
                <label>{"Nama Lengkap Wali"}</label>
                <input
                  className="form-control"
                  value={
                    representative && representative.name
                      ? representative.name
                      : ""
                  }
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Email"}</label>
                <input
                  className="form-control"
                  value={
                    representative && representative.email
                      ? representative.email
                      : ""
                  }
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"NIK"}</label>
                <input
                  className="form-control"
                  value={
                    representative && representative.nin
                      ? representative.nin
                      : ""
                  }
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Status Orang Tua"}</label>
                <select
                  className="form-control"
                  value={
                    representative && representative.status
                      ? representative.status
                      : ""
                  }
                  disabled
                >
                  <option value="" disabled>
                    {"Pilih Status Orang Tua"}
                  </option>
                  <option value="Kandung">{"Kandung"}</option>
                  <option value="Tiri">{"Tiri"}</option>
                  <option value="Angkat">{"Angkat"}</option>
                </select>
              </div>
              <div className="form-group">
                <label>{"Keterangan Orang Tua"}</label>
                <select
                  className="form-control"
                  value={
                    representative && representative.lifeStatus
                      ? representative.lifeStatus
                      : ""
                  }
                  disabled
                >
                  <option value="" disabled>
                    {"Pilih Keterangan Orang Tua"}
                  </option>
                  <option value="Hidup">{"Hidup"}</option>
                  <option value="Meninggal">{"Meninggal"}</option>
                </select>
              </div>
              <div className="form-group">
                <label>{"Kota Lahir"}</label>
                <input
                  className="form-control"
                  value={
                    representative && representative.birthCity
                      ? representative.birthCity
                      : ""
                  }
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Tanggal Lahir"}</label>
                <input
                  className="form-control"
                  type="date"
                  value={
                    representative && representative.birthDate
                      ? representative.birthDate
                      : ""
                  }
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Alamat"}</label>
                <input
                  className="form-control"
                  value={
                    representative && representative.address
                      ? representative.address
                      : ""
                  }
                  disabled
                />
              </div>

              <div className="row">
                {"RT" ? (
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>{"RT"}</label>
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        disabled
                        value={
                          representative && representative.rt
                            ? representative.rt
                            : 0
                        }
                      />
                    </div>
                  </div>
                ) : null}
                {"RW" ? (
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>{"RW"}</label>
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        disabled
                        value={
                          representative && representative.rw
                            ? representative.rw
                            : 0
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              {"Kota" ? (
                <div className="form-group">
                  <label>{"Kota"}</label>
                  <input
                    className="form-control"
                    value={
                      representative && representative.city
                        ? representative.city
                        : ""
                    }
                    disabled
                  />
                </div>
              ) : null}
              <div className="form-group">
                <label>{"Berkebutuhan Khusus"}</label>
                <input
                  className="form-control"
                  value={
                    representative && representative.disability
                      ? representative.disability
                      : ""
                  }
                  disabled
                />
              </div>

              <div className="form-group">
                <label>{"Pendidikan Akhir"}</label>
                <select
                  className="form-control"
                  style={{ maxWidth: 250 }}
                  value={
                    representative && representative.education
                      ? representative.education
                      : ""
                  }
                  disabled
                >
                  <option value="" disabled>
                    Pilih Jenjang Pendidikan
                  </option>
                  <option value="SD">{"SD"}</option>
                  <option value="SMP">{"SMP"}</option>
                  <option value="SMA">{"SMA/Sederajat"}</option>
                  <option value="D3">{"D3"}</option>
                  <option value="S1">{"D4/S1"}</option>
                  <option value="S2">{"S2"}</option>
                  <option value="S3">{"S3"}</option>
                </select>
              </div>
              <div className="form-group">
                <label>{"Pekerjaan"}</label>
                <input
                  className="form-control"
                  value={
                    representative && representative.job
                      ? representative.job
                      : ""
                  }
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Nama Instansi Tempat Bekerja"}</label>
                <input
                  className="form-control"
                  value={
                    representative && representative.companyName
                      ? representative.companyName
                      : ""
                  }
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"Penghasilan Wali"}</label>
                <input
                  className="form-control"
                  value={
                    representative && representative.salary
                      ? representative.salary
                      : ""
                  }
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"No Hp"}</label>
                <input
                  className="form-control"
                  value={
                    representative &&
                    representative.representativeCellphoneNumber
                      ? representative.representativeCellphoneNumber
                      : ""
                  }
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{"No Whatsapp"}</label>
                <input
                  className="form-control"
                  value={
                    representative && representative.noWA
                      ? representative.noWA
                      : ""
                  }
                  disabled
                />
              </div>
            </section>
          ) : null}
        </View>
      </View>
    );
  }
}

export default ParentForm;
