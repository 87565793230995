import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";

import StudentForm from "../components/Student/StudentForm";
import ParentForm from "../components/Student/ParentForm";
import UploadDocument from "../components/Student/UploadDocument";
import { handleError } from "../libs/errors";
import uuidV4 from "uuid/v4";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../App";

let urlValues;

class StudentData extends Component {
  state = {
    studentData: null,
    isLoading: true,
    selectedIndex: 1,

    documentObj: null,
    documentLists: [],
  };

  initiate = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: DATA_STUDENT_QUERY,
      variables: {
        studentId: urlValues.studentId,
      },
      fetchPolicy: "no-cache",
    });

    let student =
      result.data && result.data.studentById ? result.data.studentById : null;

    const documentLists =
      result.data && result.data.studentById
        ? result.data.studentById.Documents
        : [];

    this.setState({
      studentData: student,
      isLoading: false,
      documentLists,
    });
  };
  componentDidMount = () => {
    this.initiate();
  };

  refresh = () => {
    window.location.reload();
  };

  handleSelect = (sel) => (e) => {
    if (e) e.preventDefault();
    if (sel === "siswa") {
      this.setState({
        selectedIndex: 1,
      });
    } else if (sel === "ortu") {
      this.setState({
        selectedIndex: 2,
      });
    } else {
      this.setState({
        selectedIndex: 3,
      });
    }
  };

  addNewDocument = () => {
    this.setState({
      documentObj: {
        _id: uuidV4(),
        url: "",
        caption: "",
        description: "",
      },
    });
  };

  openEditDocument = (doc) => (e) => {
    const { __typename, ...d } = doc;
    this.setState({
      documentObj: {
        ...d,
        status: "edit",
      },
    });
  };

  cancelEdit = () => {
    this.setState({
      documentObj: null,
    });
  };

  handleInputDocument = (key) => (e) => {
    if (key === "url") {
      const file = e.target.files[0];
      if (file.size > 10240 * 1024) {
        return handleError({
          message: "Maximum allowed image size is 10MB!",
        });
      }
      let reader = new FileReader();

      reader.onloadend = async () => {
        // console.log("READER", reader);
        this.setState({
          documentObj: {
            ...this.state.documentObj,
            url: reader.result,
          },
        });
      };

      reader.readAsDataURL(file);
    } else {
      this.setState({
        documentObj: {
          ...this.state.documentObj,
          [key]: e.target.value,
        },
      });
    }
  };

  selectStudent = () => {
    urlValues = queryString.parse(this.props.location.search);
    this.props.history.replace({
      pathname: "/student_list",
      search: `sessionId=${urlValues.sessionId}`,
    });
  };

  handleInputUpdate = (key) => (e) => {
    if (key === "pin") {
      this.setState({
        studentData: {
          ...this.state.studentData,
          pin: String(e.target.value),
        },
      });
    }
  };

  render() {
    const {
      studentData,
      isLoading,
      selectedIndex,
      documentObj,
      documentLists,
    } = this.state;

    if (!studentData || isLoading) {
      return (
        <View style={{ flex: 1 }}>
          <div className="fixed-top">
            <StatusBar
              backgroundColor="#1696ff"
              barStyle="light-content"
              animated={true}
            />
          </div>

          <div className="fixed-top">
            <HeaderBar
              title={
                <Text>
                  <i
                    className="fa fa-graduation-cap"
                    style={{
                      fontSize: 20,
                    }}
                  />{" "}
                  {"Student"}
                </Text>
              }
              right={
                <TouchableOpacity onPress={this.refresh}>
                  <Text
                    style={{
                      paddingRight: 12,
                      paddingLeft: 20,
                      color: "white",
                      fontSize: 16,
                      paddingVertical: 2,
                    }}
                  >
                    <i
                      className="fa fa-sync-alt"
                      style={{
                        fontSize: 20,
                        marginBottom: 5,
                      }}
                    />
                  </Text>
                </TouchableOpacity>
              }
            />
          </div>

          <BackgroundImage />

          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                width: 240,
                height: 240,
                resizeMode: "contain",
                opacity: 0.8,
              }}
              source={require("../assets/student-reading-male-caucasian.png")}
            />
            <Text
              style={{
                fontSize: 17,
                color: "#737373",
                textAlign: "center",
                paddingHorizontal: 30,
                marginBottom: 30,
              }}
            >
              <i className="fa fa-spinner fa-spin" /> Sedang mengambil data
              siswa
            </Text>
          </View>
        </View>
      );
    }
    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-graduation-cap"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {"Student"}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section
            className="card"
            style={{ margin: 0, boxShadow: "0px 2px 4px rgba(0,0,0,0.4)" }}
          >
            <div className="card-block" style={{ padding: 20 }}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                }}
              >
                <Image
                  style={{ width: 65, height: 65 }}
                  source={
                    studentData.photoUrl
                      ? studentData.photoUrl
                      : require("../assets/owl-laptop-study.png")
                  }
                />

                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                    }}
                  >
                    <b>{studentData.name}</b>
                  </Text>
                  <Text>
                    {studentData.SchoolInformation
                      ? studentData.SchoolInformation.name
                      : ""}
                  </Text>
                  <Text>{studentData.status ? studentData.status : ""}</Text>
                  <button
                    className="btn btn-sm btn-block"
                    onClick={this.selectStudent}
                    style={{
                      marginTop: 10,
                      backgroundColor: "#00a8ff",

                      // borderWidth: 0,
                      // borderRadius: 0,
                      boxShadow: "0px -2px 5px 2px #ddd",
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        // fontSize: 20,
                      }}
                    >
                      <i className="fa fa-search" /> {"Pilih Siswa Lain"}
                    </Text>
                  </button>
                </View>
              </View>
            </div>
          </section>
        </div>

        <BackgroundImage />
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 190,
            marginBottom: 50,
          }}
        >
          <View>
            <button
              className="btn btn-sm btn-rounded shadow"
              style={{
                borderRadius: "30px",
                backgroundColor: selectedIndex === 1 ? "#00a8ff" : "#fff",
                color: selectedIndex === 1 ? "#fff" : "#bdbdbd",
                marginRight: 10,
                paddingLeft: 20,
                paddingRight: 20,
              }}
              onClick={this.handleSelect("siswa")}
            >
              Siswa
            </button>
          </View>
          <View>
            <button
              className="btn btn-sm shadow btn-rounded"
              style={{
                borderRadius: "30px",
                backgroundColor: selectedIndex === 2 ? "#00a8ff" : "#fff",
                color: selectedIndex === 2 ? "#fff" : "#bdbdbd",
                marginRight: 10,
                paddingLeft: 20,
                paddingRight: 20,
              }}
              onClick={this.handleSelect("ortu")}
            >
              Orang Tua
            </button>
          </View>
          <View>
            <button
              className="btn btn-sm shadow btn-rounded"
              style={{
                borderRadius: "30px",
                backgroundColor: selectedIndex === 3 ? "#00a8ff" : "#fff",
                color: selectedIndex === 3 ? "#fff" : "#bdbdbd",
                marginRight: 10,
                paddingLeft: 20,
                paddingRight: 20,
              }}
              onClick={this.handleSelect("berkas")}
            >
              Berkas
            </button>
          </View>
        </View>

        <View
          style={{
            marginBottom: "100vh",
          }}
        >
          {selectedIndex === 1 ? (
            <StudentForm
              student={studentData}
              handleInputUpdate={this.handleInputUpdate}
              handleSubmitUpdate={this.handleSubmitUpdate}
            />
          ) : selectedIndex === 2 ? (
            <ParentForm
              father={studentData.Father ? studentData.Father : null}
              mother={studentData.Mother ? studentData.Mother : null}
              representative={
                studentData.Representative ? studentData.Representative : null
              }
            />
          ) : (
            <UploadDocument
              document={documentObj}
              documentLists={documentLists}
              handleAddDoc={this.addNewDocument}
              handleInputDocument={this.handleInputDocument}
              saveStudentDocuments={this.saveStudentDocuments}
              openEditDocument={this.openEditDocument}
              deleteDocument={this.deleteDocument}
              cancelEdit={this.cancelEdit}
            />
          )}
        </View>

        {/* <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.selectStudent}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {"Pilih Siswa Lain"}
            </Text>
          </button>
        </div> */}
      </View>
    );
  }

  saveStudentDocuments = async (e) => {
    showLoadingSpinner();

    const { documentObj } = this.state;

    if (!documentObj.url) {
      throw {
        message: "File belum diinput",
      };
    }
    if (!documentObj.caption) {
      throw {
        message: "Nama dokumen belum diinput",
      };
    }

    try {
      urlValues = queryString.parse(this.props.location.search);

      let result = await mwvClient.mutate({
        mutation: UPLOAD_STUDENT_DOCUMENTS,
        variables: {
          studentId: urlValues.studentId,
          ...documentObj,
        },
        fetchPolicy: "no-cache",
      });

      addNotification({
        title: "SUCCESS",
        message: `Tugas Berhasil Dikirim`,
        level: "success",
      });

      this.setState({
        documentObj: null,
      });

      this.initiate();
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  deleteDocument = (doc) => async (e) => {
    showLoadingSpinner();
    try {
      if (
        window.confirm(
          `Apakah Anda yakin untuk menghapus dokumen ${doc.caption} ini ?`
        )
      ) {
        await mwvClient.mutate({
          mutation: DELETE_DOCUMENT,
          variables: {
            _id: doc._id,
          },
          fetchPolicy: "no-cache",
        });

        addNotification({
          title: "SUCCESS",
          message: `Dokumen berhasil dihapus`,
          level: "success",
        });

        this.setState({
          documentObj: null,
        });

        this.initiate();
      }
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  handleSubmitUpdate = async (e) => {
    const { studentData } = this.state;

    console.log(studentData);
    showLoadingSpinner();
    try {
      if (studentData.pin) {
        if (studentData.pin.length < 6) {
          throw {
            message: "Pin kurang dari 6 digit!",
          };
        }

        if (isNaN(studentData.pin)) {
          throw {
            message: "Pin harus berupa angka!",
          };
        }
      }

      await mwvClient.mutate({
        mutation: UPDATE_STUDENT,
        variables: {
          studentId: studentData._id,
          pin: studentData.pin,
        },
        fetchPolicy: "no-cache",
      });

      addNotification({
        title: "SUCCESS",
        message: `Data siswa berhasil disimpan`,
        level: "success",
      });

      this.initiate();
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const DATA_STUDENT_QUERY = gql`
  query listQueries($studentId: String) {
    studentById(studentId: $studentId) {
      _id
      name
      regNumber
      photoUrl
      pin
      SchoolInformation {
        _id
        name
        PREFIX
      }
      ###### DATA DAPODIK #####
      nickName
      gender
      nationalRegNumber
      birthCity
      birthOfDate
      nin
      religion
      address
      parentAddress
      rt
      rw
      dusun
      kelurahan
      kecamatan
      city
      originCity
      province
      postalCode
      typeOfStay
      transportation
      status
      phoneNumber
      cellphoneNumber
      email
      skhun
      kpsReceiver
      kpsNumber
      entryYear

      currentClassroom
      nationalExamRegNumber
      certificateNumber
      kipReceiver
      kipNumber
      kipName
      kksNumber
      regNumberBirthCertificate
      bank
      bankAccountNumber
      accountName
      pip
      pipDescription
      previousSchool
      previousSchoolAddress
      previousSchoolStatus

      #########################

      ####### CHILD ATTRIBUTE  #######
      childOrder
      fromNumberOfSiblings
      childStatus
      bloodType
      diseaseHistory
      foodAllergic
      drinkAllergic
      weight
      height
      homeDistance
      travelTime
      numberOfSiblings
      disability
      language
      ################################

      Classroom {
        _id
        name
      }

      Father {
        _id
        name
        email
        nin
        status
        lifeStatus
        relationship
        role
        birthCity
        birthDate
        disability
        address
        rt
        rw
        city
        education
        salary
        cellphoneNumber
        noWA
        job
        companyName
      }

      Mother {
        _id
        name
        email
        nin
        status
        lifeStatus
        relationship
        role
        birthCity
        birthDate
        disability
        address
        rt
        rw
        city
        education
        salary
        cellphoneNumber
        noWA
        job
        companyName
      }

      ParentsRepresentative {
        _id
        name
        email
        nin
        status
        lifeStatus
        relationship
        role
        birthCity
        birthDate
        disability
        address
        rt
        rw
        city
        education
        salary
        cellphoneNumber
        noWA
        job
        companyName
      }

      Documents {
        _id
        url
        caption
        description
      }
    }
  }
`;

const UPLOAD_STUDENT_DOCUMENTS = gql`
  mutation saveStudentDocuments(
    $studentId: String!
    $_id: ID!
    $url: String!
    $caption: String!
    $description: String
  ) {
    saveStudentDocuments(
      studentId: $studentId
      document: {
        _id: $_id
        url: $url
        caption: $caption
        description: $description
      }
    )
  }
`;

const DELETE_DOCUMENT = gql`
  mutation deleteStudentDocument($_id: String!) {
    deleteStudentDocument(_id: $_id)
  }
`;

const UPDATE_STUDENT = gql`
  mutation updateStudent($studentId: String!, $pin: String) {
    updateStudent(studentId: $studentId, input: { pin: $pin })
  }
`;

export default withRouter(StudentData);
