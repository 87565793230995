import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";

class UploadDocument extends Component {
  handleDownload = (url) => async (e) => {
    const getFile = url.split("/")[4];
    const base64File = await this.getBase64FromUrl(url);

    const linkSource = `${base64File}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = getFile;
    downloadLink.click();
  };

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  render() {
    const {
      document,
      documentLists,
      handleAddDoc,
      handleInputDocument,
      saveStudentDocuments,
      openEditDocument,
      deleteDocument,
      cancelEdit,
    } = this.props;
    return (
      <View
        style={{
          paddingRight: 5,
          paddingLeft: 5,
        }}
      >
        {!document ? (
          <button
            className="btn btn-success btn-md btn-block"
            onClick={handleAddDoc}
            style={{
              marginBottom: 20,
            }}
          >
            <i className="fa fa-plus-circle" /> Berkas Baru{" "}
          </button>
        ) : null}

        {document && !document.status ? (
          <section
            className="card "
            style={{
              padding: 10,
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            <div className="form-group">
              <label>{"Upload Berkas"} * </label>
              <input
                type="file"
                accept="image/*,application/pdf"
                className="form-control form-control-sm"
                required
                onChange={handleInputDocument("url")}
              />
            </div>
            <div className="form-group">
              <label>Nama Dokumen *</label>
              <input
                className="form-control"
                value={document.caption}
                required
                onChange={handleInputDocument("caption")}
              />
            </div>
            <div className="form-group">
              <label>Tambah Deskripsi</label>
              <input
                className="form-control"
                value={document.description}
                placeholder={"Deskripsi tambahan dokumen jika ada ada"}
                onChange={handleInputDocument("description")}
              />
            </div>

            <button
              className="btn btn-success btn-md btn-block"
              onClick={saveStudentDocuments}
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <i className="fa fa-upload" /> Upload{" "}
            </button>
            <button
              className="btn btn-danger btn-md btn-block"
              onClick={cancelEdit}
              style={{
                marginBottom: 10,
              }}
            >
              <i className="fa fa-times-circle" /> Batal{" "}
            </button>
          </section>
        ) : null}

        {documentLists.map((doc) =>
          document && document._id === doc._id ? (
            <section
              className="card "
              style={{
                padding: 10,
              }}
            >
              <div className="form-group">
                <label>{"Upload Berkas"} * </label>
                <input
                  type="file"
                  accept="image/*,application/pdf"
                  className="form-control form-control-sm"
                  required
                  onChange={handleInputDocument("url")}
                />
              </div>
              <div className="form-group">
                <label>Nama Dokumen *</label>
                <input
                  className="form-control"
                  value={document.caption}
                  required
                  onChange={handleInputDocument("caption")}
                />
              </div>
              <div className="form-group">
                <label>Tambah Deskripsi</label>
                <input
                  className="form-control"
                  value={document.description}
                  placeholder={"Deskripsi tambahan dokumen jika ada ada"}
                  onChange={handleInputDocument("description")}
                />
              </div>

              {document.url ? (
                <div className="form-group">
                  <label>Download File</label>
                  {/* <a href={document.url} download={true}>{document.url}</a> */}
                  <a href="#" onClick={this.handleDownload(document.url)}>
                    {document.url}
                  </a>
                </div>
              ) : null}
              <button
                className="btn btn-success btn-md btn-block"
                onClick={saveStudentDocuments}
              >
                <i className="fa fa-upload" /> Upload{" "}
              </button>

              <button
                className="btn btn-danger btn-md btn-block"
                onClick={cancelEdit}
                style={{
                  marginTop: 10,
                }}
              >
                <i className="fa fa-times-circle" /> Batalkan{" "}
              </button>
            </section>
          ) : (
            <section
              className="card d-flex flex-row justify-content-between"
              style={{
                padding: 10,
                marginBottom: 10,
              }}
            >
              <div className="d-flex flex-column justify-content-center">
                <div>
                  Dokumen: <b>{doc.caption}</b>
                </div>
                <div>Deskripsi: {doc.description}</div>
              </div>
              <div className="d-flex flex-column align-items-center">
                <a href={doc.url} download>
                  <p
                    style={{
                      margin: 0,
                      fontSize: 18,
                    }}
                  >
                    <i className="fa fa-download" />
                  </p>
                </a>

                <a
                  href="#"
                  style={{ marginTop: 5 }}
                  onClick={openEditDocument(doc)}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: 18,
                    }}
                  >
                    <i className="fa fa-edit" />
                  </p>
                </a>

                <a
                  href="#"
                  style={{ marginTop: 5 }}
                  onClick={deleteDocument(doc)}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: 18,
                      color: "red",
                    }}
                  >
                    <i className="fa fa-times-circle" />
                  </p>
                </a>
              </div>
            </section>
          )
        )}
      </View>
    );
  }
}

export default UploadDocument;
