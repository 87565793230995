import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { ApolloConsumer, Query, Mutation } from "react-apollo";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../../App";
import { handleError } from "../../libs/errors";
import dayjs from "dayjs";

let urlValues;

class KegiatanSekolah extends Component {
  state = {
    clickedGroup: [],
    allAspectActivityGroups: [],
  };

  componentDidMount = () => {
    this.componentWillReceiveProps(this.props);
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.allAspectActivityGroups) {
      this.setState({
        allAspectActivityGroups: nextProps.allAspectActivityGroups,
      });
    }
  };

  handleShowAspekDetail = (group) => (e) => {
    const { clickedGroup } = this.state;
    if (clickedGroup.length === 0) {
      this.setState({
        clickedGroup: [group._id],
      });
    } else {
      const idx = clickedGroup.findIndex((g) => g === group._id);

      if (idx < 0) {
        this.setState({
          clickedGroup: [...clickedGroup, group._id],
        });
      } else {
        this.setState({
          clickedGroup: clickedGroup.filter((g) => g !== group._id),
        });
      }
    }
  };

  handleDescription = (group, key) => (e) => {
    this.setState({
      allAspectActivityGroups: this.state.allAspectActivityGroups.map((gr) =>
        gr._id !== group._id
          ? gr
          : {
              ...group,
              [key]: e.target.value,
            }
      ),
    });
  };
  render() {
    const { clickedGroup, allAspectActivityGroups } = this.state;
    const { date } = this.props;

    return (
      <View
        style={{
          padding: 10,
        }}
      >
        <p
          style={{
            fontSize: 18,
            margin: 0,
          }}
        >
          <b>Kegiatan Siswa</b>
        </p>
        <p
          style={{
            fontSize: 12,
            margiBottom: "10px",
          }}
        >
          {dayjs(date).format("DD MMMM YYYY")}
        </p>

        <View
          style={{
            backgroundColor: "#fff",
          }}
        >
          {allAspectActivityGroups.map((group) => {
            const idx = clickedGroup.findIndex((g) => g === group._id);
            return (
              <div>
                <div
                  style={{
                    backgroundColor: "#d1f1f1",
                    padding: "10px",
                  }}
                  onClick={this.handleShowAspekDetail(group)}
                >
                  <b>
                    <i
                      className={`fa ${
                        idx === -1 ? "fa-caret-down" : "fa-caret-up"
                      }`}
                    />{" "}
                    {group.name}
                  </b>
                </div>

                {group.Aspects.map((aspect) => {
                  return (
                    <div className={idx === -1 ? "d-none" : "d-block"}>
                      <div
                        className={"row"}
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        <div
                          className="col-1"
                          style={{
                            padding: "0px",
                          }}
                        >
                          <div className="text-center">
                            <p
                              style={{
                                color: aspect.AspectActivityScore
                                  ? "#2029f8"
                                  : "",
                                marginBottom: "0px",
                              }}
                            >
                              {aspect.AspectActivityScore ? (
                                <i className="fa fa-check" />
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        </div>
                        <div
                          className="col-11"
                          style={{
                            padding: "0px",
                            marginBottom: "5px",
                          }}
                        >
                          ({aspect.code}) {aspect.name}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <p
                  style={{
                    marginBottom: "0px",
                    marginTop: "10px",
                  }}
                >
                  Catatan Sekolah
                </p>
                <p
                  style={{
                    fontSize: "10px",
                  }}
                >
                  Klik diluar kotak untuk menyimpan catatan
                </p>
                <textarea
                  style={{
                    marginTop: 10,
                  }}
                  className="form-control"
                  placeholder="Catatan Sekolah"
                  value={group.ashleinSchoolDescription}
                  onChange={this.handleDescription(
                    group,
                    "ashleinSchoolDescription"
                  )}
                  onBlur={this.saveDescription(group, "CATATAN SEKOLAH")}
                />

                <p
                  style={{
                    marginBottom: "0px",
                    marginTop: "10px",
                  }}
                >
                  Catatan Orang Tua
                </p>
                <p
                  style={{
                    fontSize: "10px",
                  }}
                >
                  Klik diluar kotak untuk menyimpan catatan
                </p>
                <textarea
                  style={{
                    marginTop: 10,
                  }}
                  className="form-control"
                  placeholder="Catatan Orang Tua"
                  value={group.ashleinParentDescription}
                  onChange={this.handleDescription(
                    group,
                    "ashleinParentDescription"
                  )}
                  onBlur={this.saveDescription(group, "CATATAN ORANG TUA")}
                />
              </div>
            );
          })}
        </View>
      </View>
    );
  }

  saveDescription = (group, type) => async (e) => {
    showLoadingSpinner();
    try {
      const { studentId, date, category } = this.props;
      await mwvClient.mutate({
        mutation: SAVE_DESCRIPTION,
        variables: {
          aspectGroupId: group._id,
          studentId,
          date,
          description:
            type === "CATATAN SEKOLAH"
              ? group.ashleinSchoolDescription
              : group.ashleinParentDescription,
          type,
          category,
        },
      });

      await this.props.refetch();
      addNotification({
        level: "success",
        message: "Data berhasil disimpan",
      });
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };
}

const ASPECT_GROUP_QUERY = gql`
  query listQuery(
    $academicYear: String!
    $studentId: String!
    $date: String!
    $category: String!
    $customType: String
  ) {
    allAspectActivityGroups(
      academicYear: $academicYear
      studentId: $studentId
      category: $category
      date: $date
      customType: $customType
    ) {
      _id
      name
      Aspects {
        _id
        code
        name
        aspectGroupId
        AspectActivityScore(studentId: $studentId, date: $date)
      }
      ashleinSchoolDescription(studentId: $studentId)
      ashleinParentDescription(studentId: $studentId)
    }
  }
`;
const SAVE_DESCRIPTION = gql`
  mutation saveActivityDescriptionPerGroup(
    $aspectGroupId: String!
    $studentId: String!
    $date: String!
    $description: String
    $type: String
    $category: String
  ) {
    saveActivityDescriptionPerGroup(
      aspectGroupId: $aspectGroupId
      studentId: $studentId
      date: $date
      description: $description
      type: $type
      category: $category
    )
  }
`;

const mwvClient = new ApolloClient({
  uri: "https://dev-api.mwv.softwaresekolah.co.id/graphql",
  // uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

export default withRouter((props) => {
  urlValues = queryString.parse(props.location.search);

  return (
    <ApolloConsumer>
      {(client) => (
        <Query
          query={ASPECT_GROUP_QUERY}
          client={mwvClient}
          variables={{
            _id: urlValues.sessionId,
            ...props,
            customType: props.bukuPenghubungCustomType
              ? props.bukuPenghubungCustomType
              : "",
          }}
        >
          {({ error, loading, data, refetch }) => (
            <div>
              <KegiatanSekolah
                {...props}
                client={client}
                error={error}
                loading={loading}
                allAspectActivityGroups={
                  data && data.allAspectActivityGroups
                    ? data.allAspectActivityGroups
                    : []
                }
                refetch={refetch}
              />
            </div>
          )}
        </Query>
      )}
    </ApolloConsumer>
  );
});
