import React, { Component } from "react";
import "./App.css";
import "./react-calendar.css";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "pure-react-carousel/dist/react-carousel.es.css";
import "react-notifications-component/dist/theme.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Splash from "./pages/splash";
import Login from "./pages/login";
import Register from "./pages/register";
import Explore from "./pages/explore";
import Activity from "./pages/activity";
import Help from "./pages/help";
import Account from "./pages/account";
import NotificationSystem from "react-notification-system";
import AllHelpItems from "./pages/all_help_items";
import HelpItemAnswer from "./pages/help_item_descriptions";
import FindHelp from "./pages/find_help";
import StudentSaving from "./pages/student_saving";
import EmployeeSaving from "./pages/employee_saving";
import MyProfile from "./pages/my_profile";
import ChangePassword from "./pages/change_password";
import StudentBillAndPayment from "./pages/student_bill_and_payment";
import PaymentHistory from "./pages/payment_history";
import Agenda from "./pages/agenda";
import Bill from "./pages/bill";
import StudentPerformance from "./pages/student_performance";
import AcademicPerformance from "./pages/academic_performance";
import PresensiSiswa from "./pages/presensi_siswa";
import PresensiPegawai from "./pages/presensi_pegawai";
import ExtracurricularPerformance from "./pages/extracurricular_performance";
import StudentCounseling from "./pages/counseling";
import RekapitulasiPresensiSiswa from "./pages/rekapitulasi_presensi_siswa";
import ClaimStudent from "./pages/claim_student";
import ListStudents from "./pages/list_students";
import LoginWhatsapp from "./pages/login_whatsapp";
import LoginWhatsappOfficial from "./pages/login_whatsapp_official";
import LoginGoogle from "./pages/login_google";
import InputOtp from "./pages/input_otp";
import ClassroomList from "./pages/classroom_list";
import BoardMessages from "./pages/papan_kelas";
import Classwork from "./pages/classwork";
import ClassworkList from "./pages/daftar_tugas";
import CBTPage from "./pages/cbt/cbt_page";
import CBTSubjectList from "./pages/cbt/cbt_subject_list";
import CBTPackages from "./pages/cbt/cbt_packages";
import CBTStart from "./pages/cbt/cbt_start";
import CBTPackageHisotry from "./pages/cbt/cbt_packages_history";
import CBTExplanation from "./pages/cbt/cbt_explanation";
import ClassworkExtra from "./pages/classwork_ekstra";
import ClassworkListExtra from "./pages/daftar_tugas_ekstra";
import StudentLists from "./pages/student_list";
import StudentData from "./pages/student_data";
import SubjectBoardMessages from "./pages/daftar_feed";
import ExtraBoardMessages from "./pages/daftar_feed_ekstra";
import ReportClassrooms from "./pages/report_classroom_list";
import StudentReports from "./pages/student_reports";
import SchoolSpaceMenu from "./pages/school_space_menu";
import SubjectVideoMeeting from "./pages/subject_video_meeting";
import BillExperiment from "./pages/bill_experiment";
import StudentBillDetail from "./pages/bill_detail";
import StudentSavingTopUpHistory from "./pages/student_saving_top_up_history";
import StudentBillAndPaymentNew from "./pages/student_bill/student_bill_new";
import SchoolPayHistoryNew from "./pages/student_bill/school_pay_history_new";

import StudentBillAndPaymentNewV2 from "./pages/student_bill/student_bill_new_v2";
import BorrowAs from "./pages/library/borrow_as";
import ListBook from "./pages/library/list_book";
import ListBookEmployee from "./pages/library/list_book_employee";
import BookDetail from "./pages/library/book_detail";
import CommBook from "./pages/comm_book";
import QrCode from "./pages/qr_code";
import ListSubjectPresence from "./pages/daftar_mapel_presensi";
import PerSubjectAttendance from "./pages/presensi_siswa_per_mapel";

import ClaimEmployee from "./pages/claim_employee";
import BroadcastMenu from "./pages/broadcast_menu";
import MenuKepegawaian from "./pages/list_menu_kepegawaian";
import InkaPengembanganDiri from "./pages/inka_pengembangan_diri";
import ListEmployee from "./pages/list_employees";
import InkaPembinaanPegawai from "./pages/inka_pembinaan_pegawai";
import EmployeeData from "./pages/employee_data";
import StudentListAttendances from "./pages/student_data_presensi";

import initApollo from "./libs/initApollo";
import { ApolloProvider } from "react-apollo";

import encryptedLS from "./libs/encryptedLS";
import dayjs from "dayjs";
import "dayjs/locale/id"; // load on demand
import register from "./pages/register";
dayjs.locale("id");

const apolloClient = initApollo({});
let _notificationSystem = null;
let _loadingSpinner = null;

class LoadingSpinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: props.visible,
    };
  }

  show = () => {
    this.setState({
      visible: true,
    });
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { visible } = this.state;
    return (
      <div>
        <div
          className="loader-wrapper"
          style={{
            visibility: visible ? "visible" : "hidden",
            opacity: visible ? 1 : 0,
          }}
        >
          <div className="loader" />
        </div>
        <style jsx>{`
          .loader-wrapper {
            -webkit-transition: visibility 0s linear 200ms, opacity 200ms linear; /* Safari */
            transition: visibility 0s linear 200ms, opacity 200ms linear;

            opacity: 1;
            position: fixed; /* Sit on top of the page content */
            display: block; /* Hidden by default */
            width: 100%; /* Full width (cover the whole page) */
            height: 100%; /* Full height (cover the whole page) */
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(
              243,
              243,
              243,
              0.4
            ); /* Black background with opacity */
            z-index: 9997; /* Specify a stack order in case you're using a different order for other elements */
            cursor: pointer; /* Add a pointer on hover */
          }
          .loader {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            border: 3px solid #f3f3f3; /* Light grey */
            border-top: 3px solid #3498db; /* Blue */
            border-radius: 50%;
            width: 70px;
            height: 70px;
            animation: spin 1s linear infinite;
          }

          .mini-loader {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            border: 5px solid #ccc; /* Light grey */
            border-top: 5px solid #3498db; /* Blue */
            border-radius: 50%;
            width: 45px;
            height: 45px;
            animation: spin 1s linear infinite;
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}</style>
      </div>
    );
  }
}

export default class App extends Component {
  // componentDidMount = () => {
  //   setTimeout(() => {}, 3000);
  // };
  render() {
    return (
      <ApolloProvider client={apolloClient}>
        <Router>
          <Route path={"/"} exact component={Splash} />
          {/* <Route path={"/splash"} component={Splash} /> */}
          <Route path={"/login"} component={Login} />
          <Route path={"/register"} component={Register} />
          <Route path={"/explore"} component={Explore} />
          <Route path={"/activity"} component={Activity} />
          <Route path={"/help"} component={Help} />
          <Route path={"/account"} component={Account} />
          <Route path={"/all_help_items"} component={AllHelpItems} />
          <Route path={"/help_item_descriptions"} component={HelpItemAnswer} />
          <Route path={"/find_help"} component={FindHelp} />
          <Route path={"/my_profile"} component={MyProfile} />
          <Route path={"/change_password"} component={ChangePassword} />
          <Route path={"/agenda"} component={Agenda} />
          <Route path={"/presensi_siswa"} component={PresensiSiswa} />
          <Route path={"/presensi_pegawai"} component={PresensiPegawai} />
          <Route path={"/student_saving"} component={StudentSaving} />
          <Route path={"/employee_saving"} component={EmployeeSaving} />
          <Route
            path={"/student_bill_and_payment"}
            component={StudentBillAndPayment}
          />
          <Route path={"/payment_history"} component={PaymentHistory} />
          <Route path={"/bill"} component={Bill} />

          <Route path={"/student_performance"} component={StudentPerformance} />
          <Route
            path={"/academic_performance"}
            component={AcademicPerformance}
          />
          <Route
            path={"/extracurricular_performance"}
            component={ExtracurricularPerformance}
          />
          <Route path={"/counseling"} component={StudentCounseling} />
          <Route
            path={"/rekapitulasi_presensi_siswa"}
            component={RekapitulasiPresensiSiswa}
          />
          <Route path={"/claim_student"} component={ClaimStudent} />
          <Route path={"/claim_employee"} component={ClaimEmployee} />
          <Route path={"/list_students"} component={ListStudents} />
          <Route path={"/login_whatsapp"} component={LoginWhatsapp} />
          <Route
            path={"/login_whatsapp_official"}
            component={LoginWhatsappOfficial}
          />
          <Route path={"/login_google"} component={LoginGoogle} />
          <Route path={"/input_otp"} component={InputOtp} />

          <Route path={"/classroom_list"} component={ClassroomList} />
          <Route path={"/papan_kelas"} component={BoardMessages} />
          <Route path={"/classwork"} component={Classwork} />
          <Route path={"/daftar_tugas"} component={ClassworkList} />
          <Route path={"/cbt/cbt_page"} component={CBTPage} />
          <Route path={"/cbt/cbt_subject_list"} component={CBTSubjectList} />
          <Route path={"/cbt/cbt_packages"} component={CBTPackages} />
          <Route path={"/cbt/cbt_start"} component={CBTStart} />
          <Route path={"/cbt/cbt_explanation"} component={CBTExplanation} />
          <Route
            path={"/cbt/cbt_packages_history"}
            component={CBTPackageHisotry}
          />

          <Route path={"/classwork_ekstra"} component={ClassworkExtra} />
          <Route path={"/daftar_tugas_ekstra"} component={ClassworkListExtra} />
          <Route path={"/student_list"} component={StudentLists} />
          <Route path={"/student_data"} component={StudentData} />
          <Route path={"/bill_experiment"} component={BillExperiment} />
          <Route path={"/bill_detail"} component={StudentBillDetail} />
          <Route path={"/daftar_feed"} component={SubjectBoardMessages} />
          <Route path={"/daftar_feed_ekstra"} component={ExtraBoardMessages} />
          <Route path={"/report_classroom_list"} component={ReportClassrooms} />
          <Route path={"/student_reports"} component={StudentReports} />
          <Route path={"/school_space_menu"} component={SchoolSpaceMenu} />

          <Route
            path={"/student_bill/student_bill_new"}
            component={StudentBillAndPaymentNew}
          />
          <Route
            path={"/student_bill/student_bill_new_v2"}
            component={StudentBillAndPaymentNewV2}
          />
          <Route
            path={"/student_bill/school_pay_history_new"}
            component={SchoolPayHistoryNew}
          />

          <Route
            path={"/subject_video_meeting"}
            component={SubjectVideoMeeting}
          />
          <Route
            path={"/student_saving_top_up_history"}
            component={StudentSavingTopUpHistory}
          />
          <Route path={"/library/borrow_as"} component={BorrowAs} />
          <Route path={"/library/list_book"} component={ListBook} />
          <Route
            path={"/library/list_book_employee"}
            component={ListBookEmployee}
          />
          <Route path={"/library/book_detail"} component={BookDetail} />

          <Route path={"/comm_book"} component={CommBook} />
          <Route path={"/qr_code"} component={QrCode} />
          <Route
            path={"/daftar_mapel_presensi"}
            component={ListSubjectPresence}
          />
          <Route
            path={"/presensi_siswa_per_mapel"}
            component={PerSubjectAttendance}
          />

          <Route path={"/list_menu_kepegawaian"} component={MenuKepegawaian} />
          <Route
            path={"/inka_pengembangan_diri"}
            component={InkaPengembanganDiri}
          />

          <Route path={"/broadcast_menu"} component={BroadcastMenu} />

          <Route path={"/list_employees"} component={ListEmployee} />
          <Route
            path={"/inka_pembinaan_pegawai"}
            component={InkaPembinaanPegawai}
          />

          <Route path={"/employee_data"} component={EmployeeData} />
          <Route
            path={"/student_data_presensi"}
            component={StudentListAttendances}
          />
        </Router>
        <ReactNotification />
        <NotificationSystem
          ref={(comp) => {
            _notificationSystem = comp;
          }}
        />
        <LoadingSpinner
          visible={false}
          ref={(comp) => {
            _loadingSpinner = comp;
          }}
        />
      </ApolloProvider>
    );
  }
}

export const addNotification = (params) => {
  // console.log({...params})

  // return;
  if (!_notificationSystem) return;
  if (!params.type) {
    if (params.level) {
      params.type = params.level;
    } else if (params.status) {
      params.type = params.status;
    } else {
      params.type = "warning";
    }
  }
  store.addNotification({
    // title: "Wonderful!",
    // message: "teodosii@react-notifications-component",
    // type: "success",
    insert: "bottom",
    container: "bottom-center",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 2000,
      // onScreen: true
    },
    ...params,
  });
};

export const showLoadingSpinner = () => {
  // console.log("_loadingSpinner", _loadingSpinner);
  if (!_loadingSpinner) return;
  _loadingSpinner.show();
};

export const hideLoadingSpinner = () => {
  if (!_loadingSpinner) return;
  _loadingSpinner.hide();
};
