import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import Webcam from "react-webcam";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../App";
import { handleError } from "../libs/errors";

let urlValues;

class StudentListAttendances extends Component {
  state = {
    allStudents: [],
    filterModalVisible: false,
    studentId: "",
    radius: 100,

    attendancePosition: {
      latitude: 0,
      longitude: 0,
    },
    attendanceMessage: {
      status: "",
      message: "",
    },
  };

  initiate = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: STUDENT_LISTS,
      variables: {
        _id: accountSession._id,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    // console.log({ terms });

    // this.props.history.replace({
    //   pathname: "/student_data_presensi",
    //   search: `?sessionId=${accountSession._id}&classroomId=${urlValues.classroomId}`,
    // });

    students = students.filter(
      (st) => st.classroomId === urlValues.classroomId
    );

    if (students.length === 0) {
      this.setState({
        attendanceMessage: {
          status: "Error",
          message: "Anda tidak memiliki siswa di kelas ini!",
        },
      });
    } else if (students.length > 1) {
      this.setState({
        attendanceMessage: {
          status: "Error",
          message: "Anda memiliki lebih dari 1 siswa dalam 1 kelas yang sama!",
        },
      });
    } else {
      if (navigator.geolocation) {
        console.log("Masuk Sini..!!");
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const lat = position.coords.latitude;
            const lon = position.coords.longitude;

            try {
              const result = await mwvClient.mutate({
                mutation: CREATE_ATTENDANCE,
                variables: {
                  studentId: students[0]._id,
                  imageCaputureBase64: "",
                  lat,
                  lon,
                },
              });

              this.setState({
                attendanceMessage: {
                  ...result.data.createStudentAttendanceByQRCode,
                },
              });
            } catch (err) {
              handleError(err);
            }
          },
          (error) => {
            console.log("Got Error");
          }
        );
      } else {
        console.log("Error: Geolocation is not supported by this browser.");
      }
    }

    // this.setState({
    //   allStudents: [...students],
    //   studentId: students.length > 0 ? students[0]._id : "",
    // });
  };
  componentDidMount = () => {
    this.initiate();
  };

  refresh = () => {
    window.location.reload();
  };

  render() {
    const { attendanceMessage } = this.state;
    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-graduation-cap"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {"Student"}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />
        </div>

        <BackgroundImage />

        <View
          style={{
            marginTop: 80,
          }}
        >
          <section
            className="card"
            style={{
              padding: "10px 20px 10px 20px",
              marginBottom: 10,
              // display: employee.PREFIX === "INKA" ? "block" : "none",
            }}
          >
            <div className="card-block">
              <div className="d-flex justify-content-center">
                <Image
                  style={{ width: 200, height: 200 }}
                  source={
                    attendanceMessage.status === "Error"
                      ? require("../assets/06- user-denied.png")
                      : require("../assets/02-hand-thumbs-up-like.png")
                  }
                />
              </div>
              <div>
                <p
                  align="justify"
                  style={{
                    marginBottom: 0,
                    paddingLeft: 10,
                    fontSize: 18,
                  }}
                >
                  {attendanceMessage.status !== "Error"
                    ? "Presensi Berhasil"
                    : "Presensi Gagal"}
                </p>
              </div>
            </div>
          </section>
        </View>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const STUDENT_LISTS = gql`
  query listQuery($_id: String) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
      PREFIX
      classroomId
    }
  }
`;

const CREATE_ATTENDANCE = gql`
  mutation createStudentAttendanceByQRCode(
    $studentId: String!
    $imageCaputureBase64: String
    $lat: Float
    $lon: Float
  ) {
    createStudentAttendanceByQRCode(
      studentId: $studentId
      imageCaputureBase64: $imageCaputureBase64
      lat: $lat
      lon: $lon
    ) {
      status
      message
    }
  }
`;

export default withRouter(StudentListAttendances);
